import URLCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/URLCentralWrapper.component.js"
import styles from "./App.module.css";
import { getURLEncoding } from "@jordibosch20/software_tools_package/dist/utils/url.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
    <div className={styles.App}>
      <URLCentralWrapper title={"URL Encoder / Decoder"} f={getURLEncoding}></URLCentralWrapper>
    </div>
    <div className={styles.text}>
      <p><strong>Introduction</strong>: Navigating the intricacies of web development and online content creation requires tools that simplify complex processes. Our URL Encoder/Decoder is designed to do just that, providing an essential service for professionals who deal with web addresses and online content. Whether you're encoding special characters in URLs to ensure they're web-safe or decoding URLs to edit or analyze the content, our tool offers a straightforward solution. With a focus on efficiency and accuracy, it supports your work in creating more accessible, error-free web content.</p>
      <p><strong>Why Choose Our URL Encoder/Decoder?</strong></p>
      <ul>
      <li><strong>Precision Encoding &amp; Decoding</strong>: Convert text to URL format accurately, ensuring special characters are correctly encoded for web use, and decode URLs to plain text for easy editing and review.</li>
      <li><strong>User-Friendly Interface</strong>: Designed with simplicity in mind, our tool allows for quick encoding and decoding with minimal effort, making it accessible to professionals of all technical levels.</li>
      <li><strong>Enhances Usability &amp; Compatibility</strong>: By ensuring URLs are formatted correctly, our tool helps improve your website's usability and compatibility across different browsers and platforms.</li>
      <li><strong>Saves Time</strong>: Automate the tedious task of manually encoding or decoding URLs, allowing you to focus on more strategic aspects of your work.</li>
      <li><strong>Versatile Application</strong>: Ideal for a wide range of professional tasks, including web development, digital marketing campaigns, and content creation.</li>
      </ul>
      <p><strong>How It Works</strong>:</p>
      <ol>
      <li><strong>Choose Your Function</strong>: Select whether you need to encode text into URL format or decode a URL back to text.</li>
      <li><strong>Input Your Text or URL</strong>: Paste the text you wish to encode or the URL you need to decode into the provided field.</li>
      <li><strong>Execute with a Click</strong>: With a simple click, convert your text to a properly encoded URL or decode your URL to readable text.</li>
      <li><strong>Copy and Apply</strong>: Easily copy the result for use in your web projects, ensuring your URLs are perfectly formatted for any online context.</li>
      </ol>
      <p><strong>Applications Across Fields</strong>:</p>
      <ul>
      <li><strong>Web Development</strong>: Create web-safe URLs that are compatible with all browsers and ensure server-side scripts process data correctly.</li>
      <li><strong>Digital Marketing</strong>: Optimize URLs for email campaigns and online advertising, ensuring links are error-free and trackable.</li>
      <li><strong>Content Creation</strong>: Embed links in blog posts, articles, and social media content with confidence, knowing they'll work as intended.</li>
      </ul>
      <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;